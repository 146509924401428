import styled from 'styled-components';

export const Section = styled.section`
    height: 100vh;
    padding: 16px;
    margin-bottom: 2.5rem;
    @media (min-width: 1280px) {
        padding: 26px;
  }
`

export const Flex = styled.div`
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 5rem;
`

export const FlexItem = styled.div`
    flex: 1 250px;
    margin-top: 1rem;
`

export const iFrameContainer = styled.div`
    width: 100%;
`

export const CustomIFrame = styled.iframe`
    width: 100%;
`

export const Label = styled.label`
    display: inline-block;
    font-size: 1.5rem;
    font-weight: 500;
    margin-bottom: 2rem;

`